// @nebular theming framework
@import '@nebular/theme/styles/theming';
// @nebular out of the box themes
@import '@nebular/theme/styles/themes/default';

$brownish-grey: #707070;
$sick-green: #a3c92b;
$pine-green: #115b2c;

$nb-themes: nb-register-theme(
  (
    // color
    color-primary-100: #f6fcd4,
    color-primary-200: #ecf9ab,
    color-primary-300: #d9ee7e,
    color-primary-400: #c2de5b,
    color-primary-500: $sick-green,
    color-primary-600: #87ac1f,
    color-primary-700: #6c9015,
    color-primary-800: #54740d,
    color-primary-900: #426008,

    // scrollbar
    scrollbar-color: #999,
    scrollbar-width: 0.5rem,

    // backgrounds
    background-basic-color-3: #ffffff,

    //sidebar
    sidebar-background-color: #f1f1ea,

    // menu
    menu-text-font-size: 14px,
    menu-text-font-weight: bold,
    menu-text-color: $brownish-grey,
    menu-item-active-text-color: $pine-green,
    menu-item-hover-text-color: $pine-green,
    menu-item-icon-hover-color: $sick-green,

    //select
    select-outline-color: rgba(161, 153, 127, 0.5),
    select-outline-basic-placeholder-text-color: rgba(161, 153, 127, 0.5),
    select-rectangle-border-radius: 0,
    select-rectangle-border-color: rgba(161, 153, 127, 0.5),
    select-outline-basic-border-color: rgba(161, 153, 127, 0.5),
    select-outline-basic-text-color: #727272,
    select-outline-basic-background-color: #ffffff,

    //input
    input-basic-text-color: #727272,
    input-primary-background-color: #ffffff,
    input-basic-background-color: #ffffff,
    input-primary-hover-background-color: #ffffff,
    input-basic-border-color: rgba(161, 153, 127, 0.5),
    input-success-border-color: rgba(161, 153, 127, 0.5),
    input-success-focus-border-color: rgba(161, 153, 127, 0.5),
    input-basic-focus-border-color: rgba(161, 153, 127, 0.5),
    input-primary-focus-border-color: rgba(161, 153, 127, 0.5),
    input-rectangle-border-radius: 0,

    // warning btn
    button-outline-warning-background-color: $brownish-grey,
    button-outline-warning-text-color: #ffffff,
    button-outline-warning-border-color: $brownish-grey,
    button-outline-warning-hover-background-color: $brownish-grey,
    button-outline-warning-hover-border-color: $brownish-grey,
    button-outline-warning-hover-text-color: #ffffff,
    button-rectangle-border-radius: 0
  ),
  default,
  default
);
