@import 'themes';

@mixin tables() {
  angular2-smart-table table {
    tr {
      border-bottom: solid 2px $brownish-grey;

      td,
      th {
        border: none;

        table-cell-edit-mode {
          checkbox-editor {
            display: flex;
            justify-self: center;
            justify-content: center;
            justify-items: center;

            input[type='checkbox'].form-control {
              width: 20px;
              padding: 0;
              cursor: pointer;
            }
          }
        }
      }
    }

    thead {
      tr.angular2-smart-titles {
        th {
          span,
          a,
          a:visited {
            font-size: 14px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            color: $brownish-grey;

            &.sort.asc,
            &.sort.desc {
              font-weight: bold;
              color: $sick-green;

              &::after {
                border-bottom-color: $sick-green;
              }
            }
          }
        }
      }

      tr.angular2-smart-filters {
        border-bottom: none;

        th {
          &.angular2-smart-actions-title-add a {
            background-color: transparent;
            position: absolute;
            bottom: 120%;
            left: 40%;

            &:hover {
              background-color: transparent;
            }
          }
        }
      }

      td.angular2-smart-actions {
        padding: 0.6875rem 1.125rem;

        a.angular2-smart-action {
          font-size: 1rem !important;
          color: #fff;
          border-radius: 0;
          text-transform: uppercase;
          box-shadow: none;
          border: none;
          cursor: pointer;
          font-weight: 600;
          padding: 10px;

          &.angular2-smart-action-add-create {
            background-color: $sick-green;
            border-color: $sick-green;
            margin-right: 15px;
            &:hover {
              color: inherit;
              text-decoration: none;
            }
          }

          &.angular2-smart-action-add-cancel {
            background-color: $brownish-grey;
            border-color: $brownish-grey;
            &:hover {
              text-decoration: none;
              color: inherit;
            }
          }
        }
      }
    }

    tbody tr {
      // &:not(.angular2-smart-row) {
      //   display: none;
      // }

      // &.angular2-smart-row {
      border-bottom: solid 1px rgba(161, 153, 127, 0.5);

      &:nth-child(1n),
      &:nth-child(1n):hover {
        background-color: #ffffff !important;
      }

      &:nth-child(2n),
      &:nth-child(2n):hover {
        background-color: rgba(241, 241, 234, 0.3) !important;
      }

      td {
        font-size: 16px;
        color: #000;

        &.angular2-smart-actions {
          padding: 0.875rem 1.25rem;

          a.angular2-smart-action {
            font-size: 1rem !important;
            color: #fff;
            border-radius: 0;
            text-transform: uppercase;
            box-shadow: none;
            border: none;
            cursor: pointer;
            font-weight: 600;
            padding: 10px;

            &.angular2-smart-action-edit-save {
              background-color: $sick-green;
              border-color: $sick-green;
              margin-right: 15px;

              &:hover {
                color: inherit;
                text-decoration: none;
              }
            }

            &.angular2-smart-action-edit-cancel {
              background-color: $brownish-grey;
              border-color: $brownish-grey;

              &:hover {
                color: inherit;
                text-decoration: none;
              }
            }

            &:not(:last-child) {
              border-right: none;
            }
          }
        }
      }
      // }

      &.hide-actions {
        td.angular2-smart-actions {
          a.angular2-smart-action {
            pointer-events: none;
            opacity: 0.5;
          }
        }
      }
    }
  }

  table.nb-tree-grid {
    th,
    td {
      border: none;
    }
    thead {
      tr {
        border-bottom: solid 2px $brownish-grey;
      }
      th {
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        color: $brownish-grey;
      }
    }
    tbody tr {
      &.nb-tree-grid-row {
        border-bottom: solid 1px rgba(161, 153, 127, 0.5);

        &:nth-child(1n) {
          background-color: #ffffff;
        }

        &:nth-child(2n) {
          background-color: rgba(241, 241, 234, 0.3);
        }
      }
    }
  }
}
