@use './_select-filter'

@tailwind base
@tailwind components
@tailwind utilities

@import "primeicons/primeicons.css"

@import intl-tel-input/build/css/intlTelInput.css

\:root
  --md-brownish-grey: #707070  
  --md-grey: #727272
  --md-sick-green: #a3c92b
  --md-fir-green: #8cc63f
  --md-light-cream: #f1f1ea
  --md-light-green: #e3e890

*
  box-sizing: border-box

.main-container.main-container-fixed
  background: white

img
  display: unset

.page-header
  height: 55px
  background-color: #fbfbf9
  display: flex
  align-items: center
  padding: 0 30px

  &-title
    font-size: 16px
    font-weight: 600
    color: #707070

//nb radio btn
.nb-theme-default nb-radio.status-success .native-input:enabled+.outer-circle
  border-color: grey

.angular2-smart-pagination .angular2-smart-page-item, .angular2-smart-pagination
  padding: 0 4px
  font-weight: 600
  font-size: 16px

.angular2-smart-pagination
  .page-link-next, .page-link-prev
    font-size: 16px !important

nb-card.dialog
  min-width: 640px
  max-width: 800px
  max-height: 700px
  min-height: 635px

  nb-card-header
    background-color: var(--md-light-green) !important
  
  nb-card-footer
    display: flex
    justify-content: center
    column-gap: 12px
    background-color: var(--md-light-cream)

    button
      text-transform: uppercase !important
      font-weight: 600 !important
      border: none !important
      box-shadow: none !important
      border-radius: 0 !important

    button.status-success
      background-color: var(--md-sick-green) !important
      color: white !important
      cursor: pointer !important

      &:disabled
        background: grey !important
        opacity: 0.8 !important
        pointer-events: none !important

nb-layout-column
  padding: 0 !important
  a
    text-decoration: none !important

.nb-theme-default a
  color: currentColor

.iti.iti--container
  z-index: 100000