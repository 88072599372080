
$brownish-grey: #707070
$grey: #727272
$sick-green: #a3c92b
$fir-green: #8cc63f
$light-cream: #f1f1ea

.filter-form
    background-color: #f1f1ea
    width: 100%
    display: flex
    flex-wrap: wrap
    padding: 25px
    &-flex
        display: flex
        align-items: baseline
        &-label
            margin-left: 1em
            color: #707070
        &-label-left
            color: #707070
        &-hide
            display: none !important

    &-list-filter
        flex: 1 0 100%
        display: flex
        margin-bottom: 10px

        .filter
            flex: 0 0 50%
            max-width: 50%
            padding: 15px
            display: flex
            align-items: center

            &-label
                flex: 0 0 25%
                max-width: 25%

            &-control
                flex: 0 0 75%
                max-width: 75%

    &-submit
        flex: 1 0 100%
        display: flex
        justify-content: flex-end
        padding-right: 15px
