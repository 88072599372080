@import 'themes';

@mixin select() {
  ng-select.ng-select.ng-select-multiple .ng-select-container {
    border-radius: 0;
    border: solid 1px rgba(161, 153, 127, 0.5);

    .ng-value-container .ng-value {
      background-color: #fff;
      border-radius: 0;
      color: #707070;

      .ng-value-icon {
        &.left {
          border-right: 0;
        }
        &:hover {
          background-color: rgba(163, 201, 43, 0.2);
        }
      }
    }
  }

  ng-select.ng-select .ng-select-container {
    border-radius: 0;
    border: solid 1px rgba(161, 153, 127, 0.5);

    .ng-value-container .ng-value {
      background-color: #fff;
      border-radius: 0;
      color: #707070;

      .ng-value-icon {
        &.left {
          border-right: 0;
        }
        &:hover {
          background-color: rgba(163, 201, 43, 0.2);
        }
      }
    }
  }

  ng-dropdown-panel.ng-dropdown-panel .ng-dropdown-panel-items {
    .ng-option {
      &-custom-checkbox {
        input[type='checkbox'] {
          display: inline-grid;
        }
        label {
          padding-left: 10px;
        }
      }

      &-marked {
        background-color: rgba(163, 201, 43, 0.2);
      }

      &-selected {
        background-color: #fff;
      }
    }

    .ng-option.ng-option-selected.ng-option-marked {
      background-color: rgba(163, 201, 43, 0.2);
    }
  }
}
