// themes - our custom or/and out of the box themes
@import 'themes';

// framework component themes (styles tied to theme variables)
@import '@nebular/theme/styles/globals';

@import './tables';
@import './select';

// multiselect autocomplete
@import '@ng-select/ng-select/themes/default.theme.css';

// install the framework and custom global styles
@include nb-install() {
  // framework global styles
  @include nb-theme-global();

  @include tables();
  @include select();

  /* search bar style */
  .search-bar {
    &-icon {
      color: #8f9bb3;
    }
  }
  /* End of search bar */

  button {
    &.appearance-ghost {
      &:disabled {
        background-color: transparent !important;
      }
    }
  }
}
